import axios from 'axios';
import swal from "sweetalert";
import {
    loginConfirmedAction,
    Logout,
} from '../store/actions/AuthActions';
const baseUrl = process.env.REACT_APP_BASE_URL;
const userDetails = JSON.parse(localStorage.getItem('userDetails'));

export function signUp(email, password,schoolName)
 {
    //axios call //Get username from email address
    const ipaddress= "127.0.0.1";

    const postData = {
          email:email,
          password:password,
          username:email,
          ipaddress:ipaddress,
          name:schoolName,
          returnSecureToken: true,
       
    };
    // return axios.post(
    //     `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
    //     postData,
    // );
    

           return axios.post(`${baseUrl}register/`, postData);
        }

export function login(email, password) {
    var headers   = {'Content-Type': 'multipart/form-data'};
    const postData = {
        username:email,
        password:password,
        returnSecureToken: true,
    };

    return axios.post(`${baseUrl}login/`, postData,{headers});
}

export function uploader(file, path, userID,createdBy) {
    console.log(userDetails);
    var headers   = {'Content-Type': 'multipart/form-data'};
    const postData = {
        file:file,
        userID:userDetails.userID,
        createdBy:createdBy,
        returnSecureToken: true,
    };

    return axios.post(`${baseUrl}${path}/`, postData,{headers});
}

export function uploaders(userData) {
    
    var headers   = {'Content-Type': 'multipart/form-data'};
    // const postData = {
    //     file:file,
    //     countryId:countryId,
    //     userID:userDetails.userID,
    //     createdBy:createdBy,
    //     returnSecureToken: true,
    // };
    const path = userData.path;
    return axios.post(`${baseUrl}${path}/`, userData,{headers});
}


export function saveServices(userData, path) {
    console.log(userDetails);
    var headers   = {'Content-Type': 'multipart/form-data'};

    return axios.post(`${baseUrl}${path}/`, userData,{headers});
}

export function saveService(userData, path) {
    var headers   = {'Content-Type': 'multipart/form-data'};

    return axios.put(`${baseUrl}${path}/`, userData,{headers});
}


export function getServices(path) {
    return axios.get(`${baseUrl}${path}`);
}

export function getformatSuccess(Response) 
{
    switch (Response.message) 
    {
        case 'FETCH_SUCCESS':
              swal("Alert", "Upload Successfully", "success",{ button: "Success",});
              break;
        case 'ACTIVATE_SUCCESS':
                swal("Alert", "Operation Successful", "success",{ button: "Success",});
                break;
              
        case 'SAVED_SUCCESS':
                swal("Alert", "Record Saved Successfully", "success",{ button: "Success",});
                break;
        case 'ALREADY_EXIST':
                    swal("Alert", Response.error, "warning",{ button: "Warning",});
                    break;
        case 'COMPARE_ERROR':
                        swal("Alert", Response.error, "warning",{ button: "Warning",});
                        break;
        case 'NOT_FOUND':
                    swal("Alert", Response.error, "warning",{ button: "Warning",});
                    break;

        case 'EMAIL_ACTIVATED':
                        swal("Alert", Response.error, "warning",{ button: "Warning",});
                        break;
         
        case 'DELETED':
                    swal("Alert", Response.error, "error",{ button: "Warning",});
                    break;
        case 'NOT_ACTIVATED':
                swal("Alert", Response.error, "warning",{ button: "Warning",});
                break;
        case 'SERVER_ERORR':
                    swal("Alert", Response.error, "error",{ button: "Server Error",});
                    break;    
         case 'VALIDATION_ERROR':
                        const errors = Response.errors; // Extract errors object
                        const errorMessages = Object.values(errors) // Get all error messages as arrays
                            .flat(); // Flatten the array of arrays into a single array of messages
                        swal("Alert", errorMessages[0], "error", { button: "Try Again!" }); // Show the first error
                        break;                  
               
        default:
            return '';
    }
}


export function formatSuccess(Response) 
{
    //console.log(Response.data);
    switch (Response.message) 
    {
        case 'UPLOAD_SUCCESS':
              swal("Alert", "Upload Successfully", "success",{ button: "Success",});
              break;
        case 'ACTIVATE_SUCCESS':
                swal("Alert", "Operation Successful", "success",{ button: "Success",});
                break;
        case 'SAVED_SUCCESS':
                swal("Alert", "Record Saved Successfully", "success",{ button: "Success",});
                break;
        case 'ALREADY_EXIST':
                    swal("Alert", Response.error, "warning",{ button: "Warning",});
                    break;
        case 'NOT_FOUND':
                    swal("Alert", Response.error, "warning",{ button: "Warning",});
                    break;
        case 'EMAIL_ACTIVATED':
                        swal("Alert", Response.error, "warning",{ button: "Warning",});
                        break;
         
        case 'NOT_ACTIVATED':
               swal("Alert", Response.error, "warning",{ button: "Warning",});
               break;
        case 'SERVER_ERORR':
                    swal("Alert",Response.error , "error",{ button: "Server Error",});
                    break;    
        case 'DELETED':
                        swal("Alert", Response.error, "error",{ button: "Warning",});
                        break;         
        case 'VALIDATION_ERROR':
                            const errors = Response.errors; // Extract errors object
                            const errorMessages = Object.values(errors) // Get all error messages as arrays
                                .flat(); // Flatten the array of arrays into a single array of messages
                            swal("Alert", errorMessages[0], "error", { button: "Try Again!" }); // Show the first error
                            break;                  
        default:
            return '';
    }
}

export function formatError(errorResponse) 
{
   
    switch (errorResponse.message) 
    {
        case 'EMAIL_EXISTS':            
            swal("Oops", "Email already exists", "error");
            break;
        case 'EMAIL_NOT_FOUND':
           swal("Oops", "Email not found", "error",{ button: "Try Again!",});
           break;
        case 'INVALID_PASSWORD':
            swal("Oops", "Invalid Crediential", "error",{ button: "Try Again!",});
            break;
        case 'VALIDATION_ERROR':
                swal("Oops", "Valiation Errors, Please check and correct it.", "error",{ button: "Try Again!",});
                break;
            
        // case 'USER_DISABLED':
        //     return 'User Disabled';
        //     break;
        case 'NOT_ACTIVATED':
                swal("Alert", errorResponse.error, "error",{ button: "error",});
                break;
        case 'NOT_UPLOAD':
              swal("Oops", errorResponse.error, "error",{ button: "error",});
              break;
        case 'NOT_FILE':
                swal("Oops", "Invalid File Format", "success",{ button: "error",});
                break;
        case 'SERVER_ERORR':
                    swal("Oops", errorResponse.error, "error",{ button: "error",});
                    break;

        default:
            return '';
    }
}




export function saveTokenInLocalStorage(tokenDetails) {
    tokenDetails.expireDate = new Date(
        new Date().getTime() + tokenDetails.expiresIn * 1000,
    );
    localStorage.setItem('userDetails', JSON.stringify(tokenDetails));
}

export function runLogoutTimer(dispatch, timer, navigate) {
    setTimeout(() => {
        //dispatch(Logout(history));
        dispatch(Logout(navigate));
    }, timer);
}

export function checkAutoLogin(dispatch, navigate) {
  
    const tokenDetailsString = localStorage.getItem('userDetails');
    let tokenDetails = '';
    if (!tokenDetailsString) {
        dispatch(Logout(navigate));
		return;
    }

    tokenDetails = JSON.parse(tokenDetailsString);
    let expireDate = new Date(tokenDetails.expireDate);
    let todaysDate = new Date();

    if (todaysDate > expireDate) {
        dispatch(Logout(navigate));
        return;
    }
		
    dispatch(loginConfirmedAction(tokenDetails));
	
    const timer = expireDate.getTime() - todaysDate.getTime();
    runLogoutTimer(dispatch, timer, navigate);
}

export function isLogin() {
    const tokenDetailsString = localStorage.getItem('userDetails');

    if (tokenDetailsString) {
        return true;
    }else{
        return false;
    }
}

