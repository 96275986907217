import React, { Fragment, useState, useReducer, useEffect } from "react";
import {
    Row,
    Col,
    Card,
    Table,
    Modal,
    Button,

} from "react-bootstrap";

import { Link } from "react-router-dom";
import axios from 'axios';
import {useNavigate } from "react-router-dom";
import {useDispatch } from 'react-redux';
import {saveAction } from '../../../../../../store/actions/AuthActions'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';

const inputName = [
    { label:'Verified', value:'true' },
    { label:'Rejected', value:'false' },
];


const examTopper = [{id:'111', color:'success', status:'Successful'}]
const init = false
const reducer = (state, active) =>{
    switch(active.type){
        case 'basicModal' :
            return { ...state, basicModal: !state.basicModal}
        case 'smallModal'	:
            return { ...state, smallModal: !state.smallModal}
        default :
            return state;
    }
}

const DocumentIndex = () => {
    const dispatches = useDispatch();
    const navigate = useNavigate();
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const [checked, setChecked] = useState(examTopper);
    const [unchecked, setUnChecked] = useState(true);
    const [state ,dispatch] = useReducer(reducer, init);
    const [error, setError] = useState(null);
    const [data, setData] =useState([]);
    const [amount, setAmount] =useState();
    const [fullName, setFullName] =useState();
    const [documentName, setDocumentName] =useState();
    const [ID, setID] =useState();
    const [fieldValue, setFieldValue]=useState();
    const [responses, setResponses]=useState();
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const [selectDocumentTypeValue, setselectDocumentTypeValue] = useState();
    const userID = userDetails.userID;
    const headers   = {'Content-Type': 'application/json'};

    const handleChecked = (id)=> {
        let temp = checked.map((data) => {
            if (id === data.id) {
                return { ...data, inputchecked: !data.inputchecked };
            }
            return data;
        });
        setChecked(temp);
    };
    const handleCheckedAll = (value)=> {
        let temp = checked.map((data) => {
            return { ...data, inputchecked: value };
        });
        setChecked(temp);
        setUnChecked(!unchecked);
    };

    const selectResponseOnChange = (selectedOption) =>
    {
        setFieldValue('isverfy', selectedOption.value);
        setResponses(selectedOption);
    }

    useEffect(()=>{
        fetchDocuments(userID)
    },[])

    const fetchDocuments = async () => {
        try {
            const userData= {userID:userDetails.userID}

            axios.get(`${baseUrl}documents/${userID}`).then(response =>
            {
                if(response.data.statuscode===200)
                {
                    //setData(response.data.data);
                    setData(response.data.data);

                }
                else
                {
                    throw new Error('Network response was not ok');
                }
            }).catch((error) => {
                if (error.response) {
                    //console.log(error.response);
                    //console.log("server responded");
                } else if (error.request) {
                    // console.log("network error");
                } else {
                    //console.log(error);
                }
            });

        } catch (error) {
            throw new Error(`Error fetching items: ${error.message}`);
        }
    };



    function handleModalSubmit(e)
    {
        e.preventDefault();
        const path ="documentsverified";
        const userData ={
            id :ID,
            userID:userDetails.userID,
            createdBy:userDetails.email,
        }

        dispatches(saveAction(userData,path,navigate));
        fetchDocuments(userID)
        dispatch({type:'basicModal'})
    }
    const handleInputChange = (e) => {
        setAmount(e.target.value);
    };


    const handleDownload = async (documentId) => {
        try {
            // Fetch the document directly from Django
            const response = await fetch(`${baseUrl}documentid/${documentId}`, {
                method: "GET",
            });



            // Convert response to blob
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);

            // Extract filename from response headers
            const contentDisposition = response.headers.get("Content-Disposition");
            let filename = "document.pdf"; // Default filename

            if (contentDisposition) {
                const match = contentDisposition.match(/filename="(.+?)"/);
                if (match && match.length > 1) {
                    filename = match[1];
                }
            }

            // Create a link element and trigger download
            const a = document.createElement("a");
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);

            // Clean up URL object
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Download error:", error);
            //alert("Failed to download the file.");
        }
    };

    //console.log(data);
    return (
        <Fragment>
            <ToastContainer position="top-right"></ToastContainer>
            <div className="container-fluid">
                <Row>

                    <Col lg={12}>
                        <Card>
                            <Card.Header>
                                <Card.Title>Documents Lists</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Table responsive striped security='1'>
                                    <thead>
                                    <tr>
                                        <th><strong>View</strong></th>
                                        <th><strong>NO.</strong></th>
                                        <th><strong>MatricNo</strong></th>
                                        <th><strong>Name</strong></th>
                                        <th><strong>Document</strong></th>
                                        <th><strong>Email</strong></th>
                                        <th><strong>Phone</strong></th>
                                        <th><strong>Amount</strong></th>
                                        <th><strong>TransactionId</strong></th>

                                        <th><strong>DocumentId</strong></th>
                                        <th><strong>Graduation year</strong></th>
                                        <th>Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {data
                                        .filter((item) => item.ispaid === true && item.isverify === false) // ✅ Only show items where ispaid is true
                                        .map((item, index) => (
                                            <tr key={item.documentid}>  {/* ✅ Use document ID as key for better performance */}

                                                <td><button className="btn btn-danger" onClick={() => handleDownload(item.documentid)} >Download</button></td>


                                            <td>{++index}</td>
                                            <td>{item.matricno}</td>
                                            <td>{item.fullname}</td>
                                            <td>{item.documentname}</td>
                                            <td>{item.email}</td>
                                            <td>{item.phone}</td>
                                            <td>{item.amount}</td>
                                            <td>{item.transactionid}</td>

                                            <td>{item.documentid}</td>
                                            <td>{item.graduationyear}</td>
                                            <td>
                                                <div className="d-flex">
                                                    <Link
                                                        to={"#"}
                                                        onClick={() => dispatch({type:'basicModal'}, setID(item.id), setFullName(item.fullname), setDocumentName(item.documentname))}
                                                        className="shadow btn btn-primary btn-xs sharp me-1"
                                                    >
                                                        <i className="fas fa-pencil-alt"></i>
                                                    </Link>

                                                </div>
                                            </td>
                                        </tr>
                                    ))}

                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>

            {/* <!-- Modal --> */}


            <Modal className="fade" show={state.basicModal} onHide={()=>dispatch({type:'basicModal'})}>
                <form  onSubmit={handleModalSubmit}>
                    <Modal.Header>
                        <Modal.Title>Update { documentName } of  { fullName }</Modal.Title>
                        <Button variant="" className="btn-close" onClick={() => dispatch({type:'basicModal'})}></Button>
                    </Modal.Header>
                    <Modal.Body>


                        <div className="card-body">
                            <div className="row">


                                <div className="col-sm-6 m-b30">
                                    <label className="form-label">Verification </label>
                                    <Select options={inputName} value={responses}  className="custom-react-select" onChange={selectResponseOnChange}
                                            placeholder="Response"
                                            isSearchable={true} required
                                    />
                                </div>
                                <div className="col-sm-12 m-b30">
                                    <label className="form-label">Note</label>
                                    <input type="text" className="form-control" id="note"  name="note" placeholder="Note" required />
                                </div>


                            </div>
                        </div>




                    </Modal.Body>
                    <Modal.Footer>
                        <Button  variant="danger light" onClick={() => dispatch({type:'basicModal'})}>Close</Button>
                        <button className="btn btn-primary" type="submit">Update</button>
                        {/* <Button variant="primary">Update </Button> */}
                    </Modal.Footer>
                </form>
            </Modal>





            {/* Delete Modal */}

        </Fragment>
    );

};

export default DocumentIndex;
