import React, { useContext  } from "react";
import { useDispatch, useSelector } from "react-redux";
/// React router dom
import {  Routes, Route, Outlet  } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import ScrollToTop from "./layouts/ScrollToTop";


/// Dashboard
import Home from "./components/Dashboard/Home";
import DashboardDark from "./components/Dashboard/DashboardDark";
import Dashboard2 from "./components/Dashboard/Dashboard2";
import Dashboard3 from "./components/Dashboard/Dashboard3";
import Dashboard4 from "./components/Dashboard/Dashboard4";
import Crm from "./components/Dashboard/Crm";
import Analytics from "./components/Dashboard/Analytics";
import Products from "./components/Dashboard/Products"; 
import Sales from "./components/Dashboard/Sales"; 
import Blog from "./components/Dashboard/Blog";
import Projects from "./components/Dashboard/Projects";
import Task from "./components/Dashboard/Task";


//Apps
import User from './components/AppsMenu/AppProfile/User';
import AppProfile from "./components/AppsMenu/AppProfile/AppProfile";
import AppProfile2 from "./components/AppsMenu/AppProfile/AppProfile2";
import EditProfile from "./components/AppsMenu/AppProfile/EditProfile";
import PostDetails from "./components/AppsMenu/AppProfile/PostDetails";
import Compose from "./components/AppsMenu/Email/Compose/Compose";
import Inbox from "./components/AppsMenu/Email/Inbox/Inbox";
import Read from "./components/AppsMenu/Email/Read/Read";
import Calendar from "./components/AppsMenu/Calendar/Calendar";

/// Product List
import ProductGrid from "./components/AppsMenu/Shop/ProductGrid/ProductGrid";
import ProductList from "./components/AppsMenu/Shop/ProductList/ProductList";
import ProductDetail from "./components/AppsMenu/Shop/ProductGrid/ProductDetail";
import ProductOrder from "./components/AppsMenu/Shop/ProductOrder";
import Checkout from "./components/AppsMenu/Shop/Checkout/Checkout";
import Invoice from "./components/AppsMenu/Shop/Invoice/Invoice";
import Customers from "./components/AppsMenu/Shop/Customers/Customers";

/// Charts
import SparklineChart from "./components/charts/Sparkline";
import ChartJs from "./components/charts/Chartjs";
import RechartJs from "./components/charts/rechart";
import ApexChart from "./components/charts/apexcharts";

/// Bootstrap
import UiAccordion from "./components/bootstrap/Accordion";
import UiAlert from "./components/bootstrap/Alert";
import UiBadge from "./components/bootstrap/Badge";
import UiButton from "./components/bootstrap/Button";
import UiModal from "./components/bootstrap/Modal";
import UiButtonGroup from "./components/bootstrap/ButtonGroup";
import UiListGroup from "./components/bootstrap/ListGroup";
import UiCards from "./components/bootstrap/Cards";
import UiCarousel from "./components/bootstrap/Carousel";
import UiDropDown from "./components/bootstrap/DropDown";
import UiPopOver from "./components/bootstrap/PopOver";
import UiProgressBar from "./components/bootstrap/ProgressBar";
import UiTab from "./components/bootstrap/Tab";
import UiPagination from "./components/bootstrap/Pagination";
import UiGrid from "./components/bootstrap/Grid";
import UiTypography from "./components/bootstrap/Typography";

/// Plugins
import Select2 from "./components/PluginsMenu/Select2/Select2";
import MainSweetAlert from "./components/PluginsMenu/SweetAlert/SweetAlert";
import Toastr from "./components/PluginsMenu/Toastr/Toastr";
import Lightgallery from "./components/PluginsMenu/Lightgallery/Lightgallery";
// Widget
import Widget from "./pages/Widget";
/// Table
import SortingTable from "./components/table/SortingTable/SortingTable";
import FilteringTable from "./components/table/FilteringTable/FilteringTable";
import BootstrapTable from "./components/table/BootstrapTable";
/// Form
import Element from "./components/Forms/Element/Element";
import Wizard from "./components/Forms/Wizard/Wizard";
import CkEditor from "./components/Forms/CkEditor/CkEditor";
import Pickers from "./components/Forms/Pickers/Pickers";
import FormValidation from "./components/Forms/FormValidation/FormValidation";
/// Pages
import LockScreen from "./pages/LockScreen";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";
import ForgotPassword from "./pages/ForgotPassword";
import SignupConfirmation from "./pages/SignupConfirmation"
import AccountActivation from "./pages/AccountActivation"
import ResetPassword from "./pages/ResetPassword";

import { ThemeContext } from "../context/ThemeContext";
import { navtoggle } from "../store/actions/AuthActions";
//Doocument Update
//School
import EditSchoolInfo from "./components/documee/School/Manage/Edit/School";
import SchoolListing from "./components/documee/Reports/Admins/SchoolLists";
import CreateSchoolInfo from "./components/documee/School/Create/School";
import RemoveSchoolInfo from "./components/documee/Reports/Admins/RemoveSchool";
import CreateDepartment from "./components/documee/School/Create/Department";
import CreateProgramme from "./components/documee/School/Create/Programme";
import CreateSemester from "./components/documee/School/Create/Semester";
import CreateCourses from "./components/documee/School/Create/Courses";
import CreateUser from "./components/documee/School/Create/User";
import CreateCouriers from "./components/documee/School/Create/Courier ";
import CreateShipping from "./components/documee/Shipping/Create/Shipping";
import CreateBiodata from "./components/documee/Transcripts/Create/Biodata";
import CreaateAddScores from "./components/documee/Transcripts/Create/AddScores";
import CreateGradeSystem from "./components/documee/School/Create/GradeSystem";
import CreateFaculty  from "./components/documee/School/Create/Faculty";
import CreateSession from './components/documee/School/Create/Session';
import CreateLevel from './components/documee/School/Create/Level';
import CreateAccessKey from './components/documee/Admin/Access';
import CreateRoles from './components/documee/School/Create/Roles';
import AssignedRole from './components/documee/School/AssignRole';
import CreateCurriculum from "./components/documee/School/Create/Curriculum";
import CreateDegreeClass from "./components/documee/School/Create/DegreeClass";
import CreateGraduationYear  from "./components/documee/School/Create/GraduationYear";
import Zones from "./components/documee/School/Create/Zones";
import CountryZones from "./components/documee/School/Create/CountryZones";
import CreateSchool from './components/documee/Admin/CreateSchool';
import CreateStage from './components/documee/Admin/TrackingStage';
import ChangePassword from './components/documee/School/Create/ChangePassword';
import DocumentFee  from './components/documee/School/Create/DocumentFee';
import DocumentType from './components/documee/School/Create/DocumentType';
//Transcript
//Transcript
import GenerateTranscripts from "./components/documee/Transcripts/GenerateTranscript";
import DisplayTranscript  from './components/documee/Transcripts/GeneratedResult';
import TranscriptHeader  from "./components/documee/Transcripts/TranscriptDesigns/Template1";
import TranscriptHeaderSetup from "./components/documee/Transcripts/Create/TranscriptHeaderSetup"
import TableColumnHeader from "./components/documee/Transcripts/Create/TableColumnHeader";
//Student 
import CreateStudents from "./components/documee/Student/Create/Students";

import ListDepartments from "./components/documee/Reports/Schools/ListDepartments";
import ListCourses from "./components/documee/Reports/Schools/ListCourses";
import ListProgrammes from "./components/documee/Reports/Schools/ListProgrammes";
import ListSemester from "./components/documee/Reports/Schools/ListSemesters";
import ListUsers from "./components/documee/Reports/Users/ListUsers";
import ListStudents  from "./components/documee/Reports/Students/ListStudents";
import ListShipping  from "./components/documee/Reports/Shipping/ListShipping";
import ListApplication from "./components/documee/Transcripts/ApplicationList";
import ListGrades from "./components/documee/Reports/Schools/ListGrades"
import ListSchools from "./components/documee/Admin/Activate";
import ListTranscriptTracking from './components/documee/Admin/Tracking';
import ListDocuments  from "./components/documee/Reports/Views/Documents";
import ListVerifiedDocuments  from "./components/documee/Reports/Views/Documents/verified";

import EditDepartments from "./components/documee/School/Manage/Edit/Departments";
import EditProgrammes from "./components/documee/School/Manage/Edit/Programmes";
import EditCourses from "./components/documee/School/Manage/Edit/Courses";
import EditSemesters from "./components/documee/School/Manage/Edit/Semesters";
import EditUsers from      "./components/documee/School/Manage/Edit/User";
import ChangeLogo  from  "./components/documee/Admin/Logo";
//import EditCouriers  from  "./components/documee/School/Manage/Edit/Couriers";

import ImpDepartments  from "./components/documee/School/Uploads/Departments";
import ImpSemesters from "./components/documee/School/Uploads/Semesters";
import ImpCourses from "./components/documee/School/Uploads/Courses";
import ImpProgrmammes from "./components/documee/School/Uploads/Programmes";
import ImpStudents from "./components/documee/School/Uploads/Students";
import ImpShipping from "./components/documee/Shipping/Create/Upload";
import ImpFaculty from "./components/documee/School/Uploads/Faculty";
import ImpCourier from "./components/documee/School/Uploads/Courier";
import ImpTitleHeader from "./components/documee/School/Uploads/TitleHeader";
import ImpFooterHeader from "./components/documee/School/Uploads/FooterHeader";
import ImpResults from "./components/documee/School/Uploads/Results";
import ImpSemesterResult from "./components/documee/School/Uploads/SemesterResults";
import ImpCurriculum from "./components/documee/School/Uploads/Curriculum";
import ImpState from      "./components/documee/School/Uploads/State";
import ImpSession from      "./components/documee/School/Uploads/Session";
import ImpLevel from      "./components/documee/School/Uploads/Level";
import ImpZone from    "./components/documee/School/Uploads/Zone";
import ImpGrade from    "./components/documee/School/Uploads/Grade";
//import LogoutPage from '../../../transcriptSasS/src/jsx/layouts/nav/Logout';
//Report
import ReportStudentInfo from "./components/documee/Reports/Views/Student";

const allroutes = [
  // Dashboard
    { url: "", component: <Home /> },
    { url: "dashboard", component: <Home /> },

    //School Pages

    { url: "createSchoolInfo", component: <CreateSchoolInfo/> },
    { url: "addSchoolDept", component: <CreateDepartment/> },
    { url: "addSchoolSemester", component: <CreateSemester/> },
    { url: "addSchoolProgramme", component: <CreateProgramme/> },
    { url: "addSchoolCourses", component: <CreateCourses/> },
    { url: "addUsers", component: <CreateUser/> },
    { url: "addCorriers", component: <CreateCouriers/> },
    { url: "addShipping", component: <CreateShipping/> },
    { url: "addBiodata", component: <CreateBiodata/> },
    { url: "addScores", component: <CreaateAddScores/> },
    { url: "addGrade", component: <CreateGradeSystem/> },
    { url: "addFaculty", component: <CreateFaculty/> },
    {url:  "addSession", component: <CreateSession/> },
    {url:  "addlevel", component: <CreateLevel/> },
    {url:  "accesskey", component: <CreateAccessKey/> },
    {url:  "assignRole", component: <AssignedRole/> },
    {url:  "addRoles",component: <CreateRoles/>},
    {url:  "addCurriculum",component: <CreateCurriculum/>},
    {url:  "degreeClass",component: <CreateDegreeClass/>},
    {url:  "graduationYear",component: <CreateGraduationYear/>},
    {url:  "zones",component: <Zones/>},
    {url:  "countryzones",component: <CountryZones/>},
    {url:  "setupschool",component: <CreateSchool/>},
    {url:  "createstage",component: <CreateStage/>},
    {url:  "documentfee",component: <DocumentFee/>},
    {url:  "documenttype",component: <DocumentType/>},
    //Students
    { url: "addStudents", component: <CreateStudents/> },
    { url: "changepassword", component: <ChangePassword/> },

    //Edit Pages
    { url: "editDepartments", component: <EditDepartments/> },
    { url: "editProgrammes", component:  <EditProgrammes/> },
    { url: "editCourses", component:     <EditCourses/> },
    { url: "editSemesters", component:   <EditSemesters/> },
    { url: "editSchoolInfo", component:  <EditSchoolInfo/> },
    { url: "editUsers", component: <EditUsers/> },
    { url: "changeLogo", component: <ChangeLogo/> },
    //{ url: "editCorriers", component: <EditCouriers/> },
    

    //Remove 
    { url: "removeSchoolInfo", component: <RemoveSchoolInfo /> },
    //Upload Files
    { url: "impDepartments", component: <ImpDepartments/> },
    { url: "impProgrammes", component: <ImpProgrmammes/> },
    { url: "impSemesters", component: <ImpSemesters/> },
    { url: "impCourses", component: <ImpCourses/> },
    { url: "impStudents", component: <ImpStudents/> },
    { url: "impResults", component: <ImpResults/> },
    { url: "impShipping", component: <ImpShipping/> },
    { url: "impFaculty", component: <ImpFaculty/> },
    { url: "impCourier", component: <ImpCourier/> },
    { url: "impTitleHeader", component: <ImpTitleHeader/> },
    { url: "impFooterHeader", component: <ImpFooterHeader/> },
    { url: "impSemesterResult", component: <ImpSemesterResult/> },
    { url: "impCurriculum", component: <ImpCurriculum/> },
    { url: "impState", component: <ImpState/> },
    { url: "impSession", component: <ImpSession/> },
    { url: "impLevel", component: <ImpLevel/> },
    { url: "impZone", component: <ImpZone/> },
    { url: "impGrade", component: <ImpGrade/> },

    //Transcripts
    { url: "generateTranscripts", component: <GenerateTranscripts/> },
    { url: "displayResult", component: <DisplayTranscript/> },
    { url: "transcriptHeader", component: <TranscriptHeader/> },
    { url : "tHeaderSetup", component: <TranscriptHeaderSetup/> },
    { url : "tableColumnHeader", component: <TableColumnHeader />},

    //Reports
    { url: "reportsSchoolListing", component: <SchoolListing /> },
    { url: "viewDepartments", component: <ListDepartments /> },
    { url: "viewProgrammes", component: <ListProgrammes /> },
    { url: "viewSemesters", component: <ListSemester /> },
    { url: "viewCourses", component: <ListCourses /> },
    { url: "viewUsers", component: <ListUsers /> },
    { url: "viewStudents", component: <ListStudents /> },
    { url: "viewShipping", component: <ListShipping /> },
    { url: "viewApplications", component: <ListApplication /> },
    { url: "viewGrades", component: <ListGrades /> },
    { url: "viewSchools", component: <ListSchools /> },
    { url: "listtracking", component: <ListTranscriptTracking /> },
    { url: "listdocuments", component: <ListDocuments /> },
    { url: "listverifieddocuments", component: <ListVerifiedDocuments /> },

    { url: "reportStudentInfo", component: <ReportStudentInfo /> },

    { url: "crm", component: <Crm /> },
    { url: "analytics", component: <Analytics /> },
    { url: "products", component: <Products /> },
    { url: "sales", component: <Sales /> },
    { url: "blog", component: <Blog /> },    
    { url: "project", component: <Projects /> },  
    { url: "task", component: <Task /> },  


  //Apps
   
    { url: "user", component: <User /> },   
    { url: "app-profile", component: <AppProfile /> },
    { url: "app-profile-2", component: <AppProfile2 /> },
    { url: "edit-profile", component: <EditProfile /> },
    { url: "post-details", component: <PostDetails /> },        
  // Apps  
    { url: "email-compose", component: <Compose /> },
    { url: "email-inbox", component: <Inbox /> },
    { url: "email-read", component: <Read /> },
    { url: "app-calender", component: <Calendar /> },

  // Shop
    { url: "ecom-product-grid", component: <ProductGrid /> },
    { url: "ecom-product-list", component: <ProductList /> },
    { url: "ecom-product-detail", component: <ProductDetail /> },
    { url: "ecom-product-order", component: <ProductOrder /> },
    { url: "ecom-checkout", component: <Checkout /> },
    { url: "ecom-invoice", component: <Invoice /> },
    { url: "ecom-customers", component: <Customers /> },
  // Chart
    { url: "chart-sparkline", component: <SparklineChart /> },
    { url: "chart-chartjs", component: <ChartJs /> },    
    { url: "chart-apexchart", component: <ApexChart /> },
    { url: "chart-rechart", component: <RechartJs /> },
  // Bootstrap
    { url: "ui-accordion", component: <UiAccordion /> },
    { url: "ui-alert", component: <UiAlert /> },
    { url: "ui-badge", component: <UiBadge /> },
    { url: "ui-button", component: <UiButton /> },
    { url: "ui-modal", component: <UiModal /> },
    { url: "ui-button-group", component: <UiButtonGroup /> },
    { url: "ui-list-group", component: <UiListGroup /> },
    { url: "ui-card", component: <UiCards />},
    { url: "ui-carousel", component: <UiCarousel /> },
    { url: "ui-dropdown", component: <UiDropDown /> },
    { url: "ui-popover", component: <UiPopOver /> },
    { url: "ui-progressbar", component: <UiProgressBar /> },
    { url: "ui-tab", component: <UiTab /> },
    { url: "ui-pagination", component: <UiPagination /> },
    { url: "ui-typography", component: <UiTypography /> },
    { url: "ui-grid", component: <UiGrid /> },  
  // Plugin
    { url: "uc-select2", component: <Select2 /> },
    { url: "uc-sweetalert", component: <MainSweetAlert /> },
    { url: "uc-toastr", component: <Toastr /> },   
    { url: "uc-lightgallery", component: <Lightgallery /> },
  // Widget
    { url: "widget-basic", component: <Widget /> },
  // Form
    { url: "form-element",    component: <Element /> },
    { url: "form-wizard",     component: <Wizard /> },
    { url: "form-ckeditor",   component: <CkEditor /> },
    { url: "form-pickers",    component: <Pickers /> },
    { url: "form-validation", component: <FormValidation /> },

  // table
    { url: 'table-filtering', component: <FilteringTable /> },
    { url: 'table-sorting', component: <SortingTable /> },
    { url: "table-bootstrap-basic", component: <BootstrapTable /> },


   // { url: "logout", component: <LogoutPage /> },
    
];

 
  function NotFound(){    
    const url = allroutes.map((route) => route.url);
    let path = window.location.pathname
    path = path.split('/')
    path = path[path.length - 1]    
      
    if(url.indexOf(path) <= 0){     
      return <Error404 />
    }
  }



const Markup = () => {   
    return (
      <>
          <Routes>              
              <Route path='/page-lock-screen' element= {<LockScreen />} />
              <Route path='/forgot-password'  element= {<ForgotPassword />} />
              <Route path='/reset-password'   element= {<ResetPassword />} />
              <Route path='/confirm_signup'   element= {<SignupConfirmation />} />
              <Route path='/activation_complete'   element= {<AccountActivation />} />
              <Route path='/page-error-400'   element={<Error400/>} />            
              <Route path='/page-error-403'   element={<Error403/>} />
              <Route path='/page-error-404'   element={<Error404/>} />
              <Route path='/page-error-500'   element={<Error500/>} />
              <Route path='/page-error-503'   element={<Error503/>} />     
              <Route  element={<MainLayout />} > 
                  {allroutes.map((data, i) => (
                    <Route
                      key={i}
                      exact
                      path={`${data.url}`}
                      element={data.component}
                    />
                    ))}
              </Route>                
              <Route path='*' element={<NotFound/>} />     
          </Routes>     
          <ScrollToTop />
          
      </>
    );       
};


  function MainLayout(){
    const { menuToggle , sidebariconHover} = useContext(ThemeContext);

    const dispatch = useDispatch();
    const sideMenu = useSelector(state => state.sideMenu);
    // const handleToogle = () => {
    //   dispatch(navtoggle());
    // };
    return (
      <div id="main-wrapper" className={`show ${sidebariconHover ? "iconhover-toggle": ""} ${ sideMenu ? "menu-toggle" : ""}`}>  
          <Nav />
          <div className="content-body" style={{ minHeight: window.screen.height - 45 }}>          
            <Outlet />   
          </div>
        <Footer />
      </div>
    )
  };



export default Markup;
